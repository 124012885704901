import React, { FunctionComponent } from "react";
import { DateTime } from "luxon";
import cuid from "cuid";
import Linkify from "react-linkify";
import { ReactComponent as TikTok } from "statics/media/tiktokplayicon.svg";


import { useStylesV2 } from "../stylesV2";
import { getScreenshot } from "utils/getScreenshot";
import { componentDecorator } from "components/BePlanned/Notes/Note";
import MediaItemDataCard from "./MediaItemDataCard";
import MediaItemProfileSection from "./MediaItemProfileSection";
import MediaItemImageSection from "./MediaItemImageSection";
import MediaItemDataCardVideo from "./MediaItemDataCardVideo";
import YoutubeDataCard from "./YoutubeDataCard";
import TwitterDataCard from "./TwitterDataCard";
import YoutubeVideo from "./YoutubeVideo";
import { Button, Grid } from "@material-ui/core";

interface MediaItem {
  post: any;
  role: string;
  igMetadata?: any;
  fbMetadata?: any;
}

const MediaItemV2: FunctionComponent<MediaItem> = ({ post, role, igMetadata, fbMetadata }) => {
  const classes = useStylesV2({});
  const uuid: string = cuid();

  const getDate = () => {
    if (role === "twitter") {
      if (post?.created_at && typeof post.created_at === "string" && post.created_at !== "·") {
        const parts = post.created_at.split("·");
        if (parts.length >= 2 && parts[0] && parts[1]) {
          return parts[1] + " at " + parts[0];
        }
      }
    } else if (role === "tikTok") {
      const isoString = new Date(post.created_at._seconds * 1000).toISOString();
      let dateNew = DateTime.fromISO(isoString).toFormat("dd LLL, yyyy 'at' t");
      return dateNew;
    } else if (role === "YouTube") {
      const isoString = new Date(post.publishedAt).toISOString();
      let dateNew = DateTime.fromISO(isoString).toFormat("dd LLL, yyyy 'at' t");
      return dateNew;
    } else {
      const timestamp = role === "facebook" ? post.metadata.created_time : post.metadata.timestamp;
      if (timestamp) {
        return DateTime.fromISO(timestamp).toFormat("dd LLL, yyyy 'at' t");
      }
    }
    return null; // return null if conditions are not met
  };

  const generateScreenshot = () => {
    const mediaContainer: any = document.getElementById(`mediaContainer-${uuid}`);
    mediaContainer.style.border = "solid 1px #D8D8D8";

    getScreenshot(`${role}-${uuid}`, `${role[0].toUpperCase() + role.slice(1)}_Top_3_${getDate()}`);

    mediaContainer.style.border = "none";
  };

  const setEngagementRate = (role: any, post: any) => {
    let engagementRate: any = null;

    if (role === "twitter") {
      engagementRate = post?.engagementRate;
    } else if (role === "facebook") {
      if (post?.insights?.post_impressions_unique > 0) {
        engagementRate =
          (
            (post?.insights?.post_engaged_users / post?.insights?.post_impressions_unique) *
            100
          ).toFixed(2) + "%";
      } else {
        engagementRate = "0%";
      }
    } else if (role === "tikTok") {
      // engagementRate = (post.engagementRate * 100).toFixed(2) + "%";
      engagementRate = (0).toFixed(2) + "%";
    } else if (role === "instagram") {
      if (post?.insights?.impressions > 0) {
        engagementRate =
          ((post?.insights?.engagement / post?.insights?.impressions) * 100 || 0).toFixed(2) + "%";
      } else {
        engagementRate = "0%";
      }
    } else {
      if (post?.insights?.impressions > 0) {
        engagementRate =
          ((post?.insights?.engagement / post?.insights?.impressions) * 100).toFixed(2) + "%";
      } else {
        engagementRate = "0%";
      }
    }
    return engagementRate;
  };
  const setImpressionOrShares = (role: any, post: any) => {
    let impression: any =
      post?.insights?.impressions ||
      post?.impressions ||
      post?.insights?.post_impressions_unique ||
      0;

    if (role === "tikTok") {
      impression = post.shares;
    }

    return impression;
  };
  const setAvatar = (role: any, post: any) => {
    let src: any = null;

    if (role === "tikTok") {
      src = post?.avatar || null;
    } else if (role === "instagram") {
      src = post?.metadata?.avatar || null;
    } else if (role === "facebook") {
      src = fbMetadata?.avatar || null;
    } else if (role === "twitter") {
      src = post?.profile_image_url || null;
    } else {
      src = igMetadata?.avatar || null;
    }

    return src;
  };

  const calculateTotalReactions = (postData) => {
    if (postData && postData.insights && postData.insights.post_reactions_by_type_total) {
      const reactions = postData.insights.post_reactions_by_type_total;
      return Object.values(reactions).reduce((total: any, count: any) => total + count, 0);
    }
    return 0; // Default to 0 if reactions are not found
  };

  const shortenSentence = (sentence: string, maxLength: number) => {
    if (sentence?.length <= maxLength) {
      return sentence;
    }

    const shortenedSentence = sentence ? sentence.substring(0, maxLength - 3) + "..." : "";
    return shortenedSentence;
  };

  const getAccountName = (role, post, fbMetadataName, igMetadataName) => {
    if (role === "twitter") {
      return post.accountName;
    } else if (role === "facebook") {
      return fbMetadataName;
    } else if (role === "tikTok") {
      return post.username;
    } else {
      return igMetadataName;
    }
  };
  const getLink = (role, post) => {
    if (role === "twitter") {
      return post.url;
    } else if (role === "facebook") {
      return post.metadata.permalink_url;
    } else if (role === "tikTok") {
      const link = `https://www.tiktok.com/@${post.username}/video/${post.id}`;
      return link ?? post?.embed_link;
    } else if (role === "YouTube") {
      return post.videoLink;
    } else {
      return post.metadata.permalink;
    }
  };

  return (
    <div id={`${role}-${uuid}`} className={classes.mediaTop}>
      <div
        style={{
          borderRadius: "15px",
          padding: "20px",
          height: "auto",
          background: "#FFFFFF",
        }}
        id={`mediaContainer-${uuid}`}
      >
        <div
          id="body"
          style={{
            margin: "11.5px 0px 0px 0px",
          }}
        >
          <div style={{ minHeight: "192px" }}>
            {!["YouTube", "tikTok", "facebook", "twitter"].includes(role) && (
              <MediaItemDataCard
                role={role}
                impression={setImpressionOrShares(role, post)}
                engagedUser={
                  post?.insights?.post_engaged_users ||
                  post?.insights?.engagement ||
                  post?.engagements ||
                  0
                }
                engagementRate={setEngagementRate(role, post)}
                reach={post?.insights?.reach}
                total_interactions={post?.insights?.total_interactions}
              />
            )}

            {role === "facebook" && (
              <MediaItemDataCardVideo
                role={role}
                likes={calculateTotalReactions(post) || 0}
                comments={post?.metadata?.comments_count || 0}
              />
            )}

            {role === "YouTube" && (
              <YoutubeDataCard
                commentCount={post?.commentCount || 0}
                likeCount={post?.likeCount || 0}
                viewCount={post?.viewCount || 0}
              />
            )}
            {role === "twitter" && (
              <TwitterDataCard
                impressions={post?.impressions || 0}
                likeCount={post?.likes || 0}
                retweets={post?.retweets || 0}
              />
            )}

            <MediaItemProfileSection
              avatar={setAvatar(role, post)}
              date={getDate()}
              accountName={getAccountName(role, post, fbMetadata?.name, igMetadata?.name)}
              link={getLink(role, post)}
              generateScreenshot={generateScreenshot}
            />

            <div
              style={{
                width: "100%",
                // display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #f2f2f2",
              }}
            >
              {role !== "tikTok" && (
                <>
                  {
                    role === "twitter" ? (
                      !!post.imageUrl && <MediaItemImageSection imageUrl={post.imageUrl} />
                    ) : role === "facebook" ? (
                      post.metadata.full_picture &&
                      post.metadata.full_picture !== "" && (
                        <MediaItemImageSection imageUrl={post.metadata.full_picture} />
                      )
                    ) : role === "YouTube" ? (
                      <YoutubeVideo url={post.videoLink} />
                    ) : (
                      <MediaItemImageSection
                        imageUrl={
                          post.metadata.media_type === "VIDEO"
                            ? post.metadata.thumbnail_url
                            : post.metadata.media_url
                        }
                      />
                    )
                    // ) : (
                    //   <video
                    //     controls
                    //     preload="metadata"
                    //     key={post.metadata.media_url}
                    //     className={classes.postMedia}
                    //   >
                    //     <source src={post.metadata.media_url} type="video/mp4" />
                    //     Your browser does not support the video tag.
                    //   </video>
                    // )
                  }
                </>
              )}
              {role === "tikTok" && (
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <TikTok style={{ width: "100%", height: "173px" }} />

                  <Button
                    onClick={() => window.open(post.embed_link, "_blank")}
                    className={classes.tiktokButton}
                  >
                    Play on TikTok
                  </Button>
                </Grid>
              )}
            </div>
            <div className={classes.postText}>
              <Linkify componentDecorator={componentDecorator} style={{ wordBreak: "break-word" }}>
                {role === "twitter"
                  ? `${shortenSentence(post.text, 80) || ""}`
                  : role === "facebook"
                  ? `${shortenSentence(post.metadata.message, 80) || ""}`
                  : role === "YouTube"
                  ? `${shortenSentence(post.videoTitle, 80) || ""}`
                  : role === "tikTok"
                  ? `${shortenSentence(post.title, 80) || ""}`
                  : `${shortenSentence(post.metadata.caption, 80) || ""}`}
              </Linkify>
            </div>
          </div>
          <div
            className={classes.flexContainer}
            style={{
              marginTop: "20px",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default MediaItemV2;
