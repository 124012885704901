import React, { FunctionComponent, useState } from "react";
// import { Grid, Collapse, Button } from "@material-ui/core";
import { Grid, Collapse, Button } from "@material-ui/core";
// import { Select, MenuItem, Grid, Collapse, Button } from "@material-ui/core";
import Chart from "react-apexcharts";

import { useStyles } from "./styles";
import { IndicatorProps } from "./MetricsIndicator";
// import AreaChart from "./Chart";
import AreaChartV2 from "./ChartV2";
import CharTitle from "./Chart/ChartTitle";
// import { radialBar, bar, area } from "./ChartV2/chartOptions";
import { radialBar, bar, area } from "./ChartV2/chartOptions";
import GeoChart from "./Chart/GeoChart";
import Media from "./Media";
import CustomLoader from "../CustomLoader";
// import { numberWithCommas } from "utils/numberFormat";
import { formatDate } from "utils/dateFormat";
import ErrorMessage from "components/Error/ErrorMessage";
import YouTubeTop10 from "./Media/YoutubeTop10";
import { scrollInto } from "utils/navigate";
import { DemoAreas } from "./Demographics/DemoAreas";
import AppleMusicTop10 from "./Media/AppleMusicTop10";
import SpotifySongs from "./Media/SpotifySongs";
import { capitalize } from "utils/stringFormat";
import { getScreenshot } from "utils/getScreenshot";
import DownloadActions from "components/Common/DownloadActions/DownloadActions";
import Fade from "./Fade";
// import InstagramSwitch from "./InstagramSwitch";
// import ToggleIgPost from "./ToggleIgPost";
// import InstagramMediaTitle from "./Media/InstagramMediaTitle";
// import InstagramGraph from "./Instagram/chart/InstagramGraph";

interface MetricProps extends IndicatorProps {
  igType?: string;
  igHandler?: (e: any) => void;
  mediaLoading?: boolean;
  posts: Array<any>;
  igMetadata?: any;
  fbMetadata?: any;
  prevLoading: boolean;
  prevMetrics: any;
  hiddenSubscriber?: boolean;
  dateRange?: {
    startDate: any;
    endDate: any;
  };
}

// const defaultTextStyle = {
//   color: "#606060",
//   fontFamily: "Avenir",
//   fontSize: "21px",
//   fontStyle: "normal",
//   fontWeight: 900,
//   lineHeight: "24px",
//   letterSpacing: "0.5px",
//   marginRight: 10,
// };

// const selectedTextStyle = {
//   textDecoration: "underline",
//   color: "black",
// };

const getFollowerChartTitle = (platform: string) => {
  switch (platform) {
    case "YouTube":
      return "Subscriber Count";
    case "Apple Music":
      return "Listeners";
    default:
      return "Follower Count";
  }
};

const getImpressionsChartTitle = (platform: string) => {
  switch (platform) {
    case "tikTok":
      return "Video Views";
    case "Apple Music":
      return "Plays";
    default:
      return "Total Impressions";
  }
};

const fetchingMessage = (platform: string) => {
  switch (platform) {
    case "YouTube":
      return "Fetching top 10 videos...";
    case "Apple Music":
      return "Fetching top 10 songs...";
    default:
      return "Fetching top 3 posts...";
  }
};

const MetricV2: FunctionComponent<MetricProps> = ({
  role,
  metrics: metricsData,
  icon,
  igType,
  igHandler,
  mediaLoading,
  posts,
  igMetadata,
  fbMetadata,
  url,
  prevLoading,
  prevMetrics,
  hiddenSubscriber,
  dateRange,
}) => {
  // const [totalFollowers, setTotalFollowers] = useState(0);
  const [countriesOpen, showCountries] = useState(false);
  const [citiesOpen, showCities] = useState(false);
  const totalFollowers = metricsData?.followers[metricsData?.followers?.length - 1] ?? 0;

  // const [dateList, setDateList] = useState(null);

  let metrics = metricsData;
  const midpoint = Math.ceil(metricsData.dates.length / 2);
  let metricsDates = metricsData.dates.slice(midpoint);
  metrics.dates = metricsDates;

  metrics.dates = metricsDates;

  const followers = {
    series: [
      {
        name: `${role === "Apple Music" ? "Listeners" : "Followers"}`,
        data: metrics.followers,
      },
    ],
  };
  const impressions = {
    series: [
      {
        name: `${
          role === "tikTok" ? "Video Views" : role === "Apple Music" ? "Plays" : "Impressions"
        }`,
        data: metrics.impressions || [],
      },
    ],
  };

  const page_views_total = {
    series: [
      {
        name: `Total Profile Visits`,
        data: metrics.profileViews,
      },
    ],
  };

  const reach = {
    series: [
      {
        name: "Reach",
        data: metrics?.reach || [],
      },
    ],
  };
  const videoViews = {
    series: [
      {
        name: "Video Views",
        data: (metricsDates && metrics.videoViews) || [],
      },
    ],
  };
  const listeners = {
    series: [
      {
        name: "Listners",
        data: (metricsDates && metrics.listeners) || [],
      },
    ],
  };
  const engagements = {
    series: [
      {
        name: `${
          role === "Apple Music" ? "Shazams" : role === "Spotify" ? "Streams" : "Engagements"
        }`,
        data: metrics.engagements || [],
      },
    ],
  };

  const interactions = {
    series: [
      {
        name: "Total Interactions",
        data: (metricsDates && metrics.interactions) || [],
      },
    ],
  };

  const dateLength = metricsDates?.length;
  if (role === "instagram") {
  }

  const classes = useStyles({});
  const [screenWidth] = useState(window.matchMedia("(min-width: 1320px)").matches);

  let videoPost: any[] = [];
  let imagePost: any[] = [];

  if (posts?.length) {
    for (let item of posts) {
      if (["IMAGE", "CAROUSEL_ALBUM"].includes(item?.metadata?.media_type)) {
        imagePost.push(item);
      } else if (["VIDEO"].includes(item?.metadata?.media_type)) {
        videoPost.push(item);
      }
    }
  }

  const areaOptions = {
    ...area.options,
    markers:
      dateLength > 30
        ? {}
        : {
            size: 5,
            colors: ["white"],
            strokeColor: "#5187E0",
            strokeWidth: 2,
          },
    xaxis: {
      categories: dateLength > 30 ? metricsDates : formatDate(metrics.dates),
      type: dateLength > 30 ? "datetime" : "date",
      labels: {
        datetimeUTC: false,
      },
    },
  };

  const appleMusicBarOptions = {
    ...bar.options,
    xaxis: {
      ...bar.options.xaxis,
      categories: ["18-24", "25-34", "35-44", "45-54", "55-64", "65+"],
    },
    labels: ["Men", "Women"],
  };

  const mediaCheck = (posts) =>
    posts?.length === 0 ? null : (
      <Media posts={posts} role={role} fbMetadata={fbMetadata} igMetadata={igMetadata} />
    );

  const generateScreenshot = () => {
    const chart: any = document.getElementById("city-country");
    chart.style.border = "solid 1px #D8D8D8";
    getScreenshot(
      "city-country",
      `${role[0].toUpperCase() + role.slice(1)}_audience_by_city_country`
    );

    chart.style.border = "none";
  };

  return (
    <div style={{ marginBottom: "45px" }}>
      {(posts?.length !== 0 || metrics.dates.length > 0 || mediaLoading) && (
        <div className={classes.socialLabel}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <img className={classes.icon} src={icon} alt={role} />
          </a>
          <span className={classes.statsName}> {role === "twitter" ? "X" : capitalize(role)}</span>
        </div>
      )}

      {!mediaLoading && !["YouTube", "Apple Music", "Spotify", "instagram"].includes(role)
        ? mediaCheck(posts)
        : ""}

      {!mediaLoading && role === "YouTube" ? !!posts?.length && <YouTubeTop10 posts={posts} /> : ""}
      {!mediaLoading && role === "Apple Music"
        ? !!posts?.length && <AppleMusicTop10 posts={posts} />
        : ""}
      {!mediaLoading && role === "Spotify" ? !!posts.length && <SpotifySongs posts={posts} /> : ""}

      <br />
      <Fade show={!!mediaLoading}>
        <div style={{ marginTop: "-100px", paddingBottom: "100px" }}>
          <CustomLoader width="15%" />
          <div className={classes.mediaLoader}>{fetchingMessage(role)}</div>
        </div>
      </Fade>

      {metrics.dates.length > 0 && (
        <>
          <Grid className={classes.metrics} id="metrics">
            {dateRange &&
              metricsDates &&
              igType !== "stories" &&
              role !== "instagram" &&
              !hiddenSubscriber && (
                <AreaChartV2
                  metrics={{ ...followers, areaOptions }}
                  dateGraph={metricsDates}
                  key="follower-count"
                  icon={icon}
                  role={role}
                  name={getFollowerChartTitle(role)}
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                  metricsData={metricsData}
                />
              )}

            {dateRange &&
              metricsDates &&
              !!page_views_total?.series[0]?.data?.length &&
              role === "facebook" && (
                <AreaChartV2
                  metrics={{ ...page_views_total, areaOptions }}
                  dateGraph={metricsDates}
                  key="total-profile-views"
                  icon={icon}
                  role={role}
                  name={"Total Profile Visits"}
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                  metricsData={metricsData}
                />
              )}
            {dateRange &&
              metricsDates &&
              !!reach?.series[0]?.data?.length &&
              role === "facebook" && (
                <AreaChartV2
                  metrics={{ ...reach, areaOptions }}
                  dateGraph={metricsDates}
                  key="Reach"
                  icon={icon}
                  role={role}
                  name={"Total Reach"}
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                  metricsData={metricsData}
                />
              )}

            {dateRange &&
              metricsDates &&
              !!impressions?.series[0]?.data?.length &&
              role !== "YouTube" && (
                <AreaChartV2
                  metrics={{ ...impressions, areaOptions }}
                  dateGraph={metricsDates}
                  key="total-impressions"
                  icon={icon}
                  role={role}
                  name={getImpressionsChartTitle(role)}
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                  metricsData={metricsData}
                />
              )}
            {dateRange && !!videoViews?.series[0]?.data?.length && role === "YouTube" && (
              <AreaChartV2
                metrics={{ ...videoViews, areaOptions }}
                dateGraph={metricsDates}
                key="video-views"
                icon={icon}
                role={role}
                name={"Video Views"}
                url={url}
                prevLoading={prevLoading}
                prevData={prevMetrics}
                dateRange={dateRange}
                metricsData={metricsData}
              />
            )}
            {dateRange &&
              metricsDates &&
              !!interactions?.series[0]?.data?.length &&
              ["facebook", "instagram"].includes(role) && (
                <AreaChartV2
                  metrics={{ ...interactions, areaOptions }}
                  dateGraph={metricsDates}
                  key="Total Interactions"
                  icon={icon}
                  role={role}
                  name="Total Interactions"
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                  metricsData={metricsData}
                />
              )}

            {dateRange &&
              metricsDates &&
              !!engagements?.series[0]?.data?.length &&
              role !== "YouTube" &&
              role !== "instagram" && (
                <AreaChartV2
                  metrics={{ ...engagements, areaOptions }}
                  dateGraph={metricsDates}
                  key="total-engagement"
                  icon={icon}
                  role={role}
                  name={
                    role === "Apple Music"
                      ? "Shazams"
                      : role === "Spotify"
                      ? "Streams"
                      : "Total Engagement"
                  }
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                  metricsData={metricsData}
                />
              )}
            {dateRange && !!engagements?.series[0]?.data?.length && role === "Spotify" && (
              <AreaChartV2
                metrics={{ ...listeners, areaOptions }}
                key="listeners"
                icon={icon}
                role={role}
                name="Listeners"
                url={url}
                prevLoading={prevLoading}
                prevData={prevMetrics}
                dateRange={dateRange}
                dateGraph={metricsDates}
                metricsData={metricsData}
              />
            )}
            {/* {metricsDates &&
              !!engagementRate?.series[0]?.data?.length &&
              role !== "YouTube" &&
              role !== "instagram" && (
                <AreaChartV2
                  metrics={{
                    ...engagementRate,
                    areaOptions:
                      role === "Apple Music" || role === "Spotify"
                        ? role === "Spotify"
                          ? areaOptions
                          : songPurchasesOptions
                        : engagementRateOptions,
                  }}
                  dateGraph={metricsDates}
                  key="engagement-rate"
                  icon={icon}
                  role={role}
                  name={
                    role === "Apple Music" || role === "Spotify"
                      ? role === "Spotify"
                        ? "Streams Per Followers"
                        : "Song Purchases"
                      : "Engagement Rate"
                  }
                  url={url}
                  prevLoading={prevLoading}
                  prevData={prevMetrics}
                  dateRange={dateRange}
                />
              )} */}
          </Grid>
          {role !== "twitter" && role !== "tikTok" && role !== "YouTube" && igType !== "stories" && (
            <>
              {metrics.followers[0] >= 100 ? (
                <>
                  <Grid
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gridGap: "36.5px",
                    }}
                    id="age-gender"
                  >
                    {metrics.audienceGender &&
                      metrics.audienceGender?.some((element) => !isNaN(element)) && (
                        <div id={`${role}-Audience by Gender`}>
                          <Grid key={"audience-by-gender"}>
                            <CharTitle
                              icon={icon}
                              role={role}
                              name={`${role === "Spotify" ? "Listeners" : "Audience"} by Gender`}
                              url={url}
                            />
                            <div
                              className={classes.chartContainer}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Chart
                                options={
                                  role === "Spotify" || role === "Apple Music"
                                    ? role === "Apple Music"
                                      ? {
                                          ...radialBar.options,
                                          labels: ["Men", "Women"],
                                        }
                                      : {
                                          ...radialBar.options,
                                          colors: ["#4F7FE7", "#F5576C", "#F76B1C", "#000000"],
                                          fill: {
                                            colors: ["#4F7FE7", "#F5576C", "#F76B1C", "#000000"],
                                          },
                                          labels: ["Men", "Women", "Non-binary", "Unknown"],
                                        }
                                    : radialBar.options
                                }
                                series={metrics.audienceGender}
                                type="radialBar"
                                height={315}
                                width={312}
                              />
                            </div>
                          </Grid>
                        </div>
                      )}
                    {metrics.audienceAge &&
                      metrics.audienceAge?.some((element: any) =>
                        element?.data?.some((val) => Number(val) > 0)
                      ) && (
                        <div id={`${role}-Audience by Age`}>
                          <Grid key={"audience-by-age"}>
                            <CharTitle
                              icon={icon}
                              role={role}
                              name={`${role === "Spotify" ? "Listeners" : "Audience"} by Age`}
                              url={url}
                            />
                            <div className={classes.chartContainer}>
                              <Chart
                                options={
                                  role === "Apple Music" || "Spotify"
                                    ? role === "Apple Music"
                                      ? appleMusicBarOptions
                                      : {
                                          ...bar.options,
                                          colors: ["#4F7FE7", "#F5576C", "#F76B1C", "#000000"],
                                          fill: {
                                            colors: ["#4F7FE7", "#F5576C", "#F76B1C", "#000000"],
                                          },
                                        }
                                    : bar.options
                                }
                                series={metrics.audienceAge}
                                type="bar"
                                height={315}
                              />
                            </div>
                          </Grid>
                        </div>
                      )}
                  </Grid>
                  {!!metrics?.audienceCity?.length && !!metrics?.audienceCountry?.length && (
                    <div id={`${role}-Audience by City/Country`}>
                      <CharTitle
                        icon={icon}
                        role={role}
                        name={`${role === "Spotify" ? "Listeners" : "Audience"} by City/Country`}
                        url={url}
                      />
                      <div
                        className={classes.chartContainer}
                        style={{ display: "flex", height: "auto" }}
                      >
                        <DownloadActions generateScreenshot={generateScreenshot} color="#6E8192" />
                        <Grid className={classes.cityCountry} id="city-country">
                          {metrics.audienceCountry && (
                            <GeoChart audience={metrics.audienceCountry} />
                          )}
                          <div style={{ display: "flex" }}>
                            {screenWidth && <div className={classes.mapSeparator}></div>}
                            <div className={classes.top5Locations} id="top5">
                              <div id={role}>
                                <DemoAreas
                                  metrics={{
                                    areas: metrics.countries?.topCountries,
                                    max: totalFollowers,
                                  }}
                                  type={"country"}
                                />
                                <Collapse in={countriesOpen} timeout="auto" unmountOnExit>
                                  <DemoAreas
                                    metrics={{
                                      areas: metrics.countries?.otherCountries,
                                      max: totalFollowers,
                                    }}
                                    type={"country"}
                                  />
                                </Collapse>
                                {!!metrics.countries?.topCountries?.length && (
                                  <Button
                                    onClick={() => {
                                      showCountries(!countriesOpen);
                                      countriesOpen && scrollInto(role);
                                    }}
                                    className={classes.showMore}
                                  >
                                    {countriesOpen ? "See Less" : "See More"}
                                  </Button>
                                )}
                              </div>
                              <div>
                                <DemoAreas
                                  metrics={{
                                    areas: metrics.cities?.topCities,
                                    max: totalFollowers,
                                  }}
                                  type={"city"}
                                />
                                <Collapse in={citiesOpen} timeout="auto" unmountOnExit>
                                  <DemoAreas
                                    metrics={{
                                      areas: metrics.cities?.otherCities,
                                      max: totalFollowers,
                                    }}
                                    type={"city"}
                                  />
                                </Collapse>
                                {!!metrics.cities?.topCities?.length && (
                                  <Button
                                    onClick={() => {
                                      showCities(!citiesOpen);
                                      citiesOpen && scrollInto(role);
                                    }}
                                    className={classes.showMore}
                                  >
                                    {citiesOpen ? "See Less" : "See More"}
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <ErrorMessage message="No demographics data available. Probably due to less than 100 followers." />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const areEqual = (prevProps, nextProps): any => {
  if (prevProps.metrics === nextProps.metrics) {
    return true;
  }
};

export default React.memo(MetricV2, areEqual);
