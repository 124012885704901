import { Grid } from "@material-ui/core";
import Chart from "react-apexcharts";
import { IndicatorProps } from "components/BeMeasured/MetricsIndicatorV2";
import { useStylesV2 } from "components/BeMeasured/stylesV2";
import moment from "moment";
import React, { FunctionComponent, useState, useEffect } from "react";
import { numberWithCommas } from "utils/numberFormat";
import CharTitle from "./ChartTitle";
import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";
import { area } from "components/BeMeasured/ChartV2/chartOptions";
import { formatDate, formatDateNew } from "utils/dateFormat";

interface ChartProps extends IndicatorProps {
  prevData: any;
  prevLoading: boolean;
  dateGraph: number[];
  metricsData?: any;
}
const InstagramGraph: FunctionComponent<ChartProps> = ({
  metrics: fullMetrics,
  icon,
  role,
  name,
  url,
  prevData,
  prevLoading,
  dateRange: dateRangeData,
  dateGraph,
  metricsData,
}) => {
  const midpoint = Math.ceil(fullMetrics.series[0].data.length / 2);

  const seriesArrayBeforeDate = fullMetrics.series[0].data.slice(0, midpoint);

  const seriesArrayCurrentDate = fullMetrics.series[0].data.slice(midpoint);

  let metrics = fullMetrics;
  metrics.series[0].data = seriesArrayCurrentDate;
  const dataLength = metrics.series[0].data.length;
  const [growth, setGrowth]: any = useState(0);
  // const [followerGrowth, setFollowerGrowth]: any = useState(0);
  // const [isProgress, setProgress] = useState(false);
  // const [columns, setColumns] = useState(2) as any;
  // const [columns2, setColumns2] = useState(2) as any;
  const [dateRange, setDateRange] = useState("") as any;
  const [comparedDateRange, setComparedDateRange] = useState("") as any;
  const [diffData, setDiffData] = useState("") as any;

  let currentValue;
  let followers;
  const data = metrics.series[0].data;
  let impressions;
  let interactions;
  let profileViews;
  let interactionRate;
  let engagement;
  // let engagementRate;
  // let totalViews;
  let reachs;
  const nameList = [
    "Follower Count",
    "Subscriber Count",
    "Listeners",
    "Streams",
    "Streams Per Followers",
  ];

  if (name) {
    currentValue = data.reduce((a, b) => a + b, 0);

    if (nameList.includes(name)) {
      followers = data[data.length - 1];
    }
  }

  let previousValue;
  if (name && !!Object.keys(prevData).length) {
    if (["Total Interactions"].includes(name)) {
      previousValue = prevData.interactions.reduce((a, b) => a + b, 0);
      interactions = data.reduce((a, b) => a + b, 0);
    }
    if (["Total Profile Views"].includes(name)) {
      previousValue = prevData.profileViews.reduce((a, b) => a + b, 0);
      profileViews = data.reduce((a, b) => a + b, 0);
    }
    if (["Total Reach"].includes(name)) {
      previousValue = prevData.reachs.reduce((a, b) => a + b, 0);
      reachs = data.reduce((a, b) => a + b, 0);
    }

    if (["Interaction Rate"].includes(name)) {
      previousValue = prevData.interactionsRate.reduce((a, b) => a + b, 0);
      // interactionRate = data[data.length - 1];
      interactionRate = seriesArrayCurrentDate.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
    }
    if (["Total Impressions", "Plays"].includes(name)) {
      previousValue = prevData.impressions.reduce((a, b) => a + b, 0);
      impressions = data.reduce((a, b) => a + b, 0);
    }
    if (["Total Engagement", "Shazams"].includes(name)) {
      previousValue = prevData.engagements.reduce((a, b) => a + b, 0);
      engagement = data.reduce((a, b) => a + b, 0);
    }
    if (name === "Video Views" && role === "YouTube") {
      previousValue = prevData.videoViews.reduce((a, b) => a + b, 0);
    }
    if (name === "Video Views" && role === "tikTok") {
      previousValue = prevData.impressions.reduce((a, b) => a + b, 0);
    }
    if (["Engagement Rate", "Song Purchases"].includes(name)) {
      // engagementRate = data[data.length - 1];

      previousValue = prevData.engagementRate.reduce((a, b) => a + b, 0);
      previousValue = previousValue / prevData.engagementRate.length;
    }
  }

  if (role === "YouTube" && name === "Video Views") {
    // totalViews = seriesArrayCurrentDate.reduce((accumulator, currentValue) => {
    //   return accumulator + currentValue;
    // }, 0);
  }

  const periodStart =
    seriesArrayBeforeDate && seriesArrayBeforeDate[0] < 1
      ? seriesArrayBeforeDate[0]
      : parseFloat(seriesArrayBeforeDate && seriesArrayBeforeDate[0]);
  const periodEnd =
    data && data[dataLength - 1] < 1
      ? data[dataLength - 1]
      : parseFloat(data && data[dataLength - 1]);
  let change = !isNaN(periodEnd - periodStart) ? periodEnd - periodStart : 0;

  const classes = useStylesV2({
    color:
      (name && nameList.includes(name) && (change > 0 || change === 0)) ||
      (currentValue - previousValue) / previousValue > 0 ||
      currentValue - previousValue === 0
        ? "#76EC97"
        : "red",
  });

  const getLegendContent = () => {
    if (name && ["Follower Count"].includes(name)) return "Total Followers";
    if (name && ["Total Impressions", "Plays"].includes(name)) return "Total Impressions";
    if (name && ["Total Engagement", "Shazams"].includes(name)) return "Engagement";
    if (name && ["Engagement Rate", "Song Purchases"].includes(name)) return "Engagement Rate";
    if (name && ["Total Interactions"].includes(name)) return "Total Interactions";
    if (name && ["Interaction Rate"].includes(name)) return "Interactions rate";
    if (name && ["Total Profile Views"].includes(name)) return "Total Profile Views";
    return "";
  };

  const formatDateString = (dateString, dateString2) => {
    const options = { day: "numeric", month: "short" } as any;
    const date = new Date(dateString);
    const date2 = new Date(dateString2);
    let val1 = date.toLocaleDateString("en-US", options);
    let val2 = date2.toLocaleDateString("en-US", options);
    return `Compared to ${val1} - ${val2}`;
  };

  const formatDateRange = (startDateString: any, endDateString: any) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const options: any = { day: "numeric", month: "short" };

    const formattedStartDate = startDate.toLocaleDateString("en-US", options);
    const formattedEndDate = endDate.toLocaleDateString("en-US", options);

    return `${formattedStartDate} ${startDate.getFullYear()} - ${formattedEndDate} ${endDate.getFullYear()}`;
  };

  const calculatePercentageGrowth = (oldValue: number, newValue: number): number => {
    if (oldValue === 0) {
      // Handle the case where the old value is 0 to avoid division by zero
      return newValue === 0 ? 0 : Infinity;
      // return newValue === 0 ? 0 : 0;
    }

    const percentageGrowth = ((newValue - oldValue) / Math.abs(oldValue)) * 100;
    return percentageGrowth;
  };

  const roundToDecimalPlaces = (
    number: number | null | undefined,
    decimalPlaces: number
  ): number => {
    if (number == null) {
      // console.error("Input number is null or undefined. Returning 0.");
      return 0;
    }

    if (decimalPlaces < 0) {
      // console.error(
      //   "Decimal places must be a non-negative integer. Returning the original number."
      // );
      return number;
    }

    const multiplier = Math.pow(10, decimalPlaces);
    return Math.round(number * multiplier) / multiplier;
  };

  const midpoint2 = Math.ceil(metricsData.dates.length / 2);

  let metricsDates = metricsData.dates.slice(midpoint2);

  const dateLength = metricsDates?.length;

  const areaOptions = {
    ...area.options,
    markers:
      dateLength > 30
        ? {}
        : {
            size: 5,
            colors: ["white"],
            strokeColor: "#5187E0",
            strokeWidth: 2,
          },
    xaxis: {
      categories:
        dateLength > 30 ? formatDateNew(metricsData.dates) : formatDate(metricsData.dates),
      type: dateLength > 30 ? "datetime" : "date",
      labels: {
        datetimeUTC: false,
      },
    },
  };

  // update growth rate
  useEffect(() => {
    if (!!Object.keys(prevData).length) {
      let prevTotal = seriesArrayBeforeDate.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);

      let currTotal = seriesArrayCurrentDate.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
      if (name && !["Follower Count"].includes(name)) {
        let diffVal = currTotal - prevTotal;
        setDiffData(diffVal);

        const growthRate = calculatePercentageGrowth(prevTotal, currTotal);
        setGrowth(growthRate);

        setDateRange(formatDateRange(dateRangeData.startDate, dateRangeData.endDate));

        const differenceInDays = moment(dateRangeData.endDate).diff(
          dateRangeData.startDate,
          "days"
        );
        // const olderFrom = moment(from).su
        const newStartDate = moment(dateRangeData.startDate).subtract(differenceInDays, "days");
        const newEndDate = moment(dateRangeData.startDate);

        setComparedDateRange(formatDateString(newStartDate, newEndDate));
      }

      if (name && ["Follower Count"].includes(name)) {
        const firstCount = seriesArrayCurrentDate[0];
        const currentCount = seriesArrayCurrentDate[seriesArrayCurrentDate.length - 1];

        let diffVal = currentCount - firstCount;

        setDiffData(diffVal);
        const growthRate = calculatePercentageGrowth(firstCount, currentCount);
        setGrowth(growthRate);

        setDateRange(formatDateRange(dateRangeData.startDate, dateRangeData.endDate));

        // const olderFrom = moment(from).su
        const newStartDate = moment(dateRangeData.startDate);
        const newEndDate = moment(dateRangeData.endDate);

        setComparedDateRange(formatDateString(newStartDate, newEndDate));
      }

      if (
        name &&
        [
          "Total Engagement",
          "Total Interactions",
          "Total Impressions",
          "Video Views",
          "Subscriber Count",
          "Follower",
        ].includes(name)
      ) {
      }
    }

    // if (
    //   (name && nameList.includes(name) && change > 0 && periodStart !== 0) ||
    //   ((currentValue - previousValue) / previousValue > 0 &&
    //     previousValue !== 0 &&
    //     !isNaN(currentValue - previousValue))
    // ) {
    //   // setProgress(true);
    // } else {
    //   // setProgress(false);
    // }

    const contentLength = ((name && nameList.includes(name) && Number(change) > 0 ? "+" : "") +
      (name && nameList.includes(name)
        ? numberWithCommas(Number(diffData) || 0)
        : name === "Engagement Rate"
        ? currentValue.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + "%"
        : numberWithCommas(currentValue)
      ).length) as any;

    const col2Len = getLegendContent().length;
    if (contentLength) {
      // setColumns(calculateColumnWidth(contentLength) ?? 2);
    }
    if (col2Len) {
      // setColumns2(calculateColumnWidth(col2Len) ?? 2);
    }

    // eslint-disable-next-line
  }, [prevData]);

  return (
    <div id={`${role}-${name}`}>
      <div className={classes.chartContainer}>
        <CharTitle icon={icon} role={role} name={name} url={url} dateRange={dateRange} />
        <Grid container className={classes.uispacing}>
          <Grid xs={2} md={2} className={classes.innerChartText}>
            {name && nameList.includes(name) && numberWithCommas(Number(followers))}
            {name &&
              ["Total Interactions"].includes(name) &&
              numberWithCommas(Number(interactions))}
            {name &&
              ["Total Profile Views"].includes(name) &&
              numberWithCommas(Number(profileViews))}
            {name && ["Total Reach"].includes(name) && numberWithCommas(Number(reachs))}
            {name &&
              interactionRate &&
              ["Interaction Rate"].includes(name) &&
              roundToDecimalPlaces(interactionRate, 2)}
            {name && ["Total Impressions"].includes(name) && numberWithCommas(Number(impressions))}
            {name &&
              ["Total Engagement", "Shazams"].includes(name) &&
              numberWithCommas(Number(engagement))}
          </Grid>
          <Grid xs={1} md={1}></Grid>

          <Grid
            xs={2}
            md={2}
            className={classes.innerChartText}
            style={{
              minWidth: 100,
            }}
          >
            {Number(diffData) > 0 && "+"}
            {numberWithCommas(Number(diffData))}
          </Grid>
          <Grid
            xs={2}
            md={2}
            style={{
              marginRight: 10,
              marginLeft: 10,
              color: "#782121",
            }}
          >
            <span
              style={{
                backgroundColor: Number(growth) > 0 ? "#EBFAEB" : "#FAEBEB",
                color: Number(growth) > 0 ? "#237821" : "#782121",
                fontWeight: 900,
                fontFamily: "Avenir",
                padding: "2px 4px",
                borderRadius: 4,
              }}
            >
              {Number(growth) > 0 && (
                <ArrowUpwardOutlinedIcon
                  style={{
                    width: 7.92,
                    height: 7.92,
                  }}
                />
              )}
              {name && (
                <span
                  style={{
                    fontSize: 12,
                  }}
                >
                  {growth.toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + "%"}
                </span>
              )}
            </span>
          </Grid>
        </Grid>
        <Grid container className={classes.areaChartLegend2}>
          <Grid xs={3} md={3}>
            {getLegendContent()}
          </Grid>

          <Grid xs={4} md={4}>
            {" "}
            {!["Follower Count", "Followers"].includes(name as any) && comparedDateRange}
          </Grid>
        </Grid>

        {seriesArrayCurrentDate.length && (
          <Chart
            options={{
              ...areaOptions,
            }}
            series={metrics.series ?? metrics.series}
            type="area"
            height={250}
          />
        )}
      </div>
    </div>
  );
};

const areEqual = (prevProps, nextProps): any => {
  if (prevProps.metrics === nextProps.metrics) {
    return true;
  }
};

export default React.memo(InstagramGraph, areEqual);
