export const historicalData = async (
  startDate: string,
  endDate: string,
  platform: string,
  username: string
) => {
  try {
    if (!process.env.REACT_APP_FUNC_BASE_URL) {
      throw new Error("REACT_APP_FUNC_BASE_URL is not defined");
    }
    if (!platform) {
      throw new Error("Platform is not defined");
    }
    if (!username) {
      throw new Error("Username is not defined");
    }
    const url = `${process.env.REACT_APP_FUNC_BASE_URL}${platform}-historicalData`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, startDate, endDate }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return { error: error.message };
  }
};
export const historicalDataV2 = async (
  startDate: string,
  endDate: string,
  platform: string,
  username: string,
  email?: string
) => {
  try {
    if (!process.env.REACT_APP_FUNC_BASE_URL) {
      throw new Error("REACT_APP_FUNC_BASE_URL is not defined");
    }
    if (!platform) {
      throw new Error("Platform is not defined");
    }
    if (!username) {
      throw new Error("Username is not defined");
    }
    const url = `${process.env.REACT_APP_FUNC_BASE_URL}${platform}-historicalData`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, startDate, endDate,email }),
    });
    if (!response.ok) {
      return null;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    return null;
  }
};
