import { makeStyles } from "@material-ui/core/styles";

export const useStylesV2 = makeStyles((theme) => ({
  dateLabel: {
    fontSize: "15px",
    lineHeight: "22px",
    fontFamily: "'Avenir Black', sans-serif",
    color: "#000000",
    marginRight: 15,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontFamily: "Avenir",
      fontSize: "16px",
      fontWeight: 900,
      lineHeight: "20px",
      letterSpacing: "0em",
    },
  },
  dateInput: {
    width: "100%",
    height: "100%",
    fontSize: "14px",
    fontFamily: "Avenir Book, sans-serif",
    lineHeight: "22px",
    borderRadius: "2px",
    marginTop: "8.5px",
    maxHeight: "45px",
    cursor: "pointer",
    background: "#FFF",
  },
  profileContainer: {
    borderRadius: "15px",
    border: "1px solid transparent",
    display: "flex",
    justifyContent: "center",
    padding: "50px",
    background: "#FFFFFF",

    "@media screen and (max-width: 450px)": {
      padding: "50px 20px",

      "& > :first-child": {
        width: "100%",
        maxWidth: "100% !important",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: 30,
      },
    },
  },
  avatar: {
    minWidth: "140px",
    minHeight: "140px",
    mixBlendMode: "normal",
    borderRadius: "50%",
    border: "solid 1px #f2f2f2",
  },
  name: {
    textAlign: "center",
    marginTop: "23px",
    fontFamily: "Avenir Black, sans-serif",
    fontSize: "18px",
  },
  stats: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // marginLeft: "62px",
  },
  icon: {
    width: "21.467px",
    height: "24px",
    [theme.breakpoints.down("sm")]: {
      width: "12vh",
      height: "14vh",
    },
  },
  iconContainer: {
    margin: "3px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "10px",
      width: "100%",
    },
  },
  AvatarIcon: {
    width: "80px",
    height: "80px",
    flexShrink: 0,
    background: "#000",
  },
  AvatarSvg: {
    width: "80px",
    height: "80px",
    flexShrink: 0,
    mixBlendMode: "normal",
    background: "#000",
  },
  indicator: {
    height: "62px",
    marginTop: "25px",
    display: "flex",
    "&:first-child": {
      marginTop: "0px",
    },
  },
  progress: {
    width: "96%",
    paddingTop: "5px",
    marginLeft: "15px",
  },
  metricLabel: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "8px",
    fontSize: "15px",
    fontFamily: "Avenir Book, sans-serif",
    lineHeight: "22px",
    alignItems: "baseline",
  },
  statsWrapper: {
    margin: "62.5px 0px 0px 0px",
  },
  socialLabel: {
    display: "flex",
    alignItems: "center",

    "& > .MuiSvgIcon-root": {
      color: "slategrey",
    },
  },
  socialLabel2: {
    padding: "20px 10px 20px 0px",
    justifyContent: "space-between",
    marginLeft: 24,

    [theme.breakpoints.down("sm")]: {},
  },
  dateRangeStyle: {
    color: "#000",
    textAlign: "right",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 350,
    lineHeight: "24px",
    letterSpacing: "0.5px",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  statsName: {
    color: "#000",
    fontFamily: "Avenir",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "24px",
    letterSpacing: "0.5px",
    marginLeft: 15,
    marginRight: 15,
    "& > .MuiSvgIcon-root": {
      color: "slategrey",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
    },
  },
  chartContainer: {
    borderRadius: "15px",
    padding: "20px",
    height: "415px",
    background: "#FFFFFF",
    marginBottom: 30,
    width: "100%",

    "@media screen and (max-width: 900px)": {
      flexDirection: "column",
      padding: "0px",
      paddingRight: "20px",
    },
  },
  metrics: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(480px, 1fr))",
    gridGap: "36.5px",
    gridRowGap: 0,
  },
  media: {
    display: "flex",
    // paddingRight: 60,
    "@media screen and (max-width: 900px)": {
      paddingRight: 0,
    },
  },
  followers: {
    color: "#000",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 350,
    textAlign: "center",
    lineHeight: "20px" /* 125% */,
  },
  followersCount: {
    color: "#000",
    textAlign: "center",
    fontFamily: "Avenir",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "32px" /* 133.333% */,
    letterSpacing: "0.5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  followerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  mapSeparator: {
    border: "1px solid #8D9AA9",
    mixBlendMode: "normal",
    margin: "-20px 0px",
    opacity: 0.25,
    transform: "rotate(180deg)",
    width: "1px",
    background: "#8D9AA9",
  },
  selectIcon: {
    paddingRight: "20.5px",
    color: "#000000",
    width: "17px",
    height: "28px",
  },
  selectIconOpen: {
    transform: "none",
  },
  selectInput: {
    minWidth: "148px",
    textAlign: "left",
  },
  selectOverride: {
    padding: "11.5px 20.5px",

    "& ul": {
      height: (props: any) => props.listLength,
      overflowX: "hidden",
    },
    "& li": {
      fontSize: "14px",
      fontFamily: "Avenir Book, sans-serif",
      color: "#000000",
    },
    "&.MuiSelect-select": {
      "&:focus": {
        backgroundColor: "#fff",
      },
      "&:active": {
        backgroundColor: "#fff",
      },
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
  },
  cityCountry: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(500px, 1fr))",
    gridGap: "0px",
    minHeight: "390px",
    height: "auto",
    width: "100%",
  },
  top5Locations: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "15px",
    width: "100%",
    padding: "8px 15px 28px 15px",
  },
  areaChartLegend: {
    fontFamily: "Avenir Book, sans-serif",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    marginLeft: "10px",
    // color: (props: any) => props.color,
  },
  areaChartLegend2: {
    color: "#000",
    fontFamily: "Avenir",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 350,
    lineHeight: "16px",
    marginLeft: "20px",
    // color: (props: any) => props.color,
  },
  mediaContainer: {
    height: "100%",
    minHeight: "582px",
    padding: "30px",
    boxSizing: "border-box",
  },
  mediaTop: {
    width: "calc(100%/3)",
    marginRight: "34.5px",
    padding: 5,
    "&:last-child": {
      marginRight: "0px",
    },
    "@media screen and (max-width: 1150px)": {
      width: "100%",
      marginRight: 0,
    },
  },
  postDate: {
    fontFamily: "Avenir Book, sans-serif",
    fontSize: 13,
    lineHeight: "12px",
  },
  postText: {
    maxHeight: "100%",
    marginTop: "15px",
    fontSize: "15px",
    lineHeight: "25px",
    wordWrap: "break-word",
    fontFamily: "Avenir Book, sans-serif",
  },
  reactIcon: { width: "23px", height: "23px" },
  reactValue: {
    fontSize: "15px",
    lineHeight: "18px",
    fontFamily: "Avenir Book, sans-serif",
  },
  postMetric: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "15px",
    lineHeight: "40px",
    fontFamily: "Avenir Book, sans-serif",
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Avenir Book, sans-serif",
    fontSize: "19px",
  },
  postAvatar: {
    width: 50,
    height: 50,
    border: "solid 1px #f2f2f2",
  },
  postMedia: {
    width: "100%",
    height: "173px",
    objectFit: "contain",
  },
  mediaLoader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "15%",
    marginTop: 12,
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  marginTop: {
    marginTop: "18px",
  },
  editLink: {
    textDecoration: "none",
    marginLeft: "6px",
    cursor: "pointer",
  },
  editIcon: {
    width: "15px",
    height: "15px",
    fill: "#5c5c5c",
  },
  clientList: {
    display: "flex",
    flexDirection: "column",
    "@media screen and (max-width: 768px)": {
      width: "100%",
      display: "block",

      "& > :last-child": {
        width: "100%",
      },
    },
  },
  clientList2: {
    display: "flex",
    flexDirection: "column",
    "@media screen and (max-width: 768px)": {
      width: "100%",
      display: "block",

      "& > :last-child": {
        width: "100%",
      },
    },
  },
  autoCompleteField: {
    background: "#fff",
    borderRadius: 15,
    width: 224,
    // width: (props: any) => props.autoCompleteWidth || 224,
    height: 45,
    marginTop: 8.5,
    marginRight: 15,

    "& > :first-child": {
      fontFamily: "'Avenir Book', sans-serif",
      fontSize: "15px",
      padding: "0px 15px",
      height: 45,
    },

    "& > div > input": {
      padding: "5px !important",
    },

    "& > div > div > :first-child > span > svg": {
      color: "black",
      width: 17,
    },

    "& > div > div > :last-child > span > svg": {
      color: "black",
      width: 17,
    },

    "@media screen and (max-width: 768px)": {
      width: "100%",
      minWidth: 224,
    },
    [theme.breakpoints.down("sm")]: {
      width: 300,
      marginRight: 0,
    },
  },
  autoCompleteField2: {
    background: "#fff",
    borderRadius: 2,
    // width: 224,
    // width: (props: any) => props.autoCompleteWidth || 224,
    height: 45,
    marginTop: 8.5,
    // marginRight: 15,

    "& > :first-child": {
      fontFamily: "'Avenir Book', sans-serif",
      fontSize: "15px",
      padding: "0px 15px",
      height: 45,
    },

    "& > div > input": {
      padding: "5px !important",
    },

    "& > div > div > :first-child > span > svg": {
      color: "black",
      width: 17,
    },

    "& > div > div > :last-child > span > svg": {
      color: "black",
      width: 17,
    },

    "@media screen and (max-width: 768px)": {
      width: "100%",
      minWidth: 224,
    },
    [theme.breakpoints.down("sm")]: {
      width: 300,
      marginRight: 0,
    },
  },
  autoComplete: {
    "& > div > div > fieldset": {
      borderColor: "#E8E8E8",
      borderRadius: 15,
    },

    "& > div > .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E8E8E8",
    },

    "& > div > .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E8E8E8",
      borderRadius: 15,
    },
  },
  autoComplete2: {
    "& > div > div > fieldset": {
      borderColor: "#E8E8E8",
      borderRadius: 15,
    },

    "& > div > .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E8E8E8",
    },

    "& > div > .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E8E8E8",
      borderRadius: 15,
    },
  },
  txtField: {
    "&.MuiTextField-root": {
      width: 194,
      height: 45,
    },
  },
  info: {
    fontFamily: "Avenir Book, sans-serif",
    fontSize: 12,
    backgroundColor: "slategrey",
    maxWidth: 308,
  },
  arrow: {
    color: "slategrey !important",
  },
  growthRate: {
    marginRight: 21,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.10)",
    },
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    backgroundColor: "#ffffff",
    height: 80,
    "& th": {
      fontWeight: 900,
      fontFamily: "'Avenir Black', sans-serif",
    },
  },
  tableCell: {
    height: "22px",
    left: "0%",
    right: "94.24%",
    top: "calc(50% - 22px/2 - 0.04px)",
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "15px",
    lineHeight: "22px",
    color: "#000000",
  },
  tableCell2: {
    borderRight: "1px solid lightgray",
  },
  tableRow: {
    display: "flex",
    padding: "16px",
    alignItems: "center",
    gap: "8px",
    flex: "1 0 0",
    alignSelf: "stretch",
  },
  tableRowDetails: {
    color: "#000",
    fontFamily: "Avenir",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 350,
    lineHeight: "20px",
  },
  row: {
    height: 60,
  },
  postDetails: {
    height: "22px",
    left: "0%",
    right: "0%",
    top: "calc(50% - 22px/2 - 14px)",
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "15px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#000000",
    textDecoration: "none",
  },
  post: {
    display: "flex",
    flexDirection: "column",
  },
  link: {
    color: "#1d4e89 !important",
    textDecoration: "none",
  },
  archiveLabel: {
    height: "22px",
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    textTransform: "capitalize",
    textDecorationLine: "underline",
    color: "#929292",
  },
  showMore: {
    color: "#1d4e89",
    textTransform: "none",
    fontFamily: "'Avenir Black', sans-serif",
    marginLeft: 6,

    "&:focus": {
      backgroundColor: "#fff",
    },
    "&:active": {
      backgroundColor: "#fff",
    },
  },
  growthIcon: {
    width: "20px",
    height: "20px",
    marginBottom: "-6px",
    marginRight: "-1px",
  },
  playButton: {
    textDecoration: "none",
    color: "#626262",
    display: "flex",
    justifyContent: "center",
  },
  userName: {
    color: "#000",
    fontFamily: "Avenir",
    fontSize: "27px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "32px",
    letterSpacing: "0.5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: 20,
  },

  //   cardUi: {
  //     borderRadius: "15px",
  //     border: "1px solid transparent",
  //     display: "flex",
  //     justifyContent: "center",

  //     padding: "50px 0px",
  //     background: "#FFFFFF",
  //     "@media screen and (max-width: 450px)": {
  //       padding: "50px 30px",

  //       "& > :first-child": {
  //         width: "100%",
  //         maxWidth: "100% !important",
  //         display: "flex",
  //         justifyContent: "space-between",
  //         alignItems: "center",
  //         paddingBottom: 30,
  //       },
  //     },
  //   },

  MediaItemsImageSection: {
    width: "100%",
    height: 336,
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  innerChartText: {
    color: "#000",
    fontFamily: "Avenir",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "24px",
    letterSpacing: "0.5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "12px",
    },
  },
  uispacing: {
    marginLeft: "20px",
  },
  innerChartText2: {
    textAlign: "center",
    fontFamily: "Avenir",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "24px",
    letterSpacing: "0.5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "12px",
    },
  },

  chartIcon: {
    width: "26px",
    height: "30px",
    [theme.breakpoints.down("sm")]: {
      width: "20px",
      height: "24px",
    },
  },
  tiktokButton: {
    backgroundColor: "#FF355A",
    borderRadius: "15px",
    padding: "11.5px, 8px, 11.5px, 8px",
    color: "white",
    cursor: "pointer",
    fontWeight: 900,
    fontSize: "15px",
    fontFamily: "Avenir",
    textTransform: "none",
    marginTop: "15px",
    "&:hover": {
      backgroundColor: "#ff637d !important",
    },
  },
  biostyle: {
    fontSize : "13px",
  }
}));
