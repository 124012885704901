import React, { FunctionComponent } from "react";

import { useStylesV2 } from "../stylesV2";
// import MediaItem from "./MediaItem";
import ErrorMessage from "components/Error/ErrorMessage";
import MediaItemInstagramImage from "./MediaItemInstagramImage";

interface MediaProps {
  posts: Array<any>;
  role: string;
  igMetadata?: any;
  fbMetadata?: any;
  avatar: any;
}
const InstagramMediaImage: FunctionComponent<MediaProps> = ({
  posts,
  role,
  igMetadata,
  fbMetadata,
  avatar,
}) => {
  const classes = useStylesV2({});
  let postsMarkup;
  const calculateEngagementRate = (
    totalInteractions: number,
    impressions: number
  ): number => {
    const engagementRate = (totalInteractions / impressions) * 100;
    return Number(engagementRate.toFixed(2));
  };
  if (posts?.length > 0) {
    let newPost = posts.sort((a, b) => {
      let engagementRateB = calculateEngagementRate(
        b?.insights?.total_interactions ?? 0,
        b?.insights?.impressions ?? 0
      );
      let engagementRateA = calculateEngagementRate(
        a?.insights?.total_interactions ?? 0,
        a?.insights?.impressions ?? 0
      );
      return engagementRateB - engagementRateA;
    });
    postsMarkup = newPost.map((post, index) => (
      <MediaItemInstagramImage
        post={post}
        key={index}
        role={role}
        fbMetadata={fbMetadata}
        igMetadata={igMetadata}
        profileAvatar={avatar}
      />
    ));
  }

  return (
    <div
      style={{ marginBottom: "0px" }}
      id={`${role}-Top 3 highest engagement posts`}
    >
      {posts?.length === 0 ? (
        <ErrorMessage message=" No available posts for this period" />
      ) : (
        <div className={classes.media} id="media">
          {postsMarkup}
        </div>
      )}
    </div>
  );
};

const areEqual = (prevProps, nextProps): any => {
  if (prevProps.posts === nextProps.posts) {
    return true;
  }
};

export default React.memo(InstagramMediaImage, areEqual);
