import React, { FunctionComponent, useEffect, useState } from "react";
// import { Grid, Avatar } from "@material-ui/core";
import { Grid } from "@material-ui/core";

import { useStyles } from "./styles";
// import MetricsIndicator from "./MetricsIndicator";
import FacebookIcon from "statics/media/fb-vector.png";
import TwitterIcon from "statics/media/twitter-vector.png";
import InstagramIcon from "statics/media/instagram-vector.png";
import TiktokIcon from "statics/media/tiktok.png";
import YouTubeIcon from "statics/media/youtube.png";
import SpotifyIcon from "statics/media/spotify-logo.png";
import {
  getFbData,
  getIgData,
  getSpotifyData,
  getTiktokData,
  getTwitterData,
  getYouTubeData,
  // getAppleMusicData, // Disable for now
} from "../../utils/metrics";
// import userIcon from "statics/media/name.svg";
import ErrorMessage from "components/Error/ErrorMessage";
import { capitalize } from "utils/stringFormat";
import { BeMeasuredProps } from "types/analytics";
import Image from "components/Common/Image/Image";
import Loader from "../Loader";
import MetricsIndicatorV2 from "./MetricsIndicatorV2";
import CustomLoader from "components/CustomLoader";
import MetricV2IGIPosts from "./Instagram/MetricV2IGIPosts";
import MetricV2Instagram from "./Instagram/MetricV2Instagram";
import MetricV2 from "./MetricV2";
import TiktokMetrics from "./TikTok/TiktokMetrics";

export const BeMeasured: FunctionComponent<BeMeasuredProps> = ({
  metrics: {
    fbMetrics,
    igMetrics,
    twitterMetrics,
    tiktokMetrics,
    youtubeMetrics,
    appleMusicMetrics,
    spotifyMetrics,
  },
  loading: {
    fbLoading,
    fbMetricsLoading,
    igLoading,
    igMetricsLoading,
    // appleMusicLoading, // Disable for now
    twitterLoading,
    twitterMetricsLoading,
    tiktokLoading,
    tiktokMetricsLoading,
    youtubeLoading,
    youtubeMetricsLoading,
    spotifyLoading,
    spotifyMetricsLoading,
    prevLoading,
  },
  posts: {
    fbPosts,
    igPosts,
    // appleMusicPosts, // Disable for now
    tiktokPosts,
    twitterPosts,
    youtubePosts,
    spotifyPosts,
  },
  igType,
  igHandler,
  user,
  igMetadata,
  fbMetadata,
  prevMetrics,
  dateRange,
  newIgMetrics,
}) => {
  const classes = useStyles({});

  const [totalFollowers, setTotalFollowers] = useState(0);
  useEffect(() => {
    igType !== "stories" &&
      setTotalFollowers(
        igMetrics.length > 0 &&
          igMetrics[igMetrics.length - 1].metadata?.followers_count
      );
  }, [igMetrics, igType]);

  const fbFollowerCount =
    fbMetrics.length > 0 &&
    fbMetrics[fbMetrics.length - 1].followers?.followers_count;
  const igFollowerCount = totalFollowers;
  const twitterFollowerCount =
    twitterMetrics.length > 0 && twitterMetrics[0].total_followers;
  const tiktokFollowerCount =
    tiktokMetrics.length > 0 &&
    tiktokMetrics[tiktokMetrics.length - 1].total_followers;
  const youtubeFollowerCount =
    youtubeMetrics.length > 0 &&
    youtubeMetrics[youtubeMetrics.length - 1].total_subscribers;
  const appleMusicListenersCount =
    appleMusicMetrics.length > 0 &&
    appleMusicMetrics[appleMusicMetrics.length - 1].listeners;
  const spotifyFollowerCount =
    spotifyMetrics.length > 0 &&
    spotifyMetrics[spotifyMetrics.length - 1].followers;

  const fbData = fbFollowerCount || fbFollowerCount===0 ? getFbData(fbMetrics) : null;
  const igData = getIgData(newIgMetrics, igType);
  igType === "stories"
    ? getIgData(igMetrics, igType)
    : igFollowerCount >= 100 && getIgData(igMetrics, igType);
  // const igData =
  //   igType === "stories"
  //     ? getIgData(igMetrics, igType)
  //     : igFollowerCount >= 100 && getIgData(igMetrics, igType);
  const twitterData = getTwitterData(twitterMetrics);
  const tiktokData = getTiktokData(tiktokMetrics);
  const youtubeData = getYouTubeData(youtubeMetrics);
  // Disable for now
  // const appleMusicData = getAppleMusicData(appleMusicMetrics);
  const spotifyData = getSpotifyData(spotifyMetrics);

  const prevFbData = fbFollowerCount && getFbData(prevMetrics.facebook);
  const prevIgData =
    igType === "stories"
      ? getIgData(prevMetrics.instagram, igType)
      : igFollowerCount >= 100 && getIgData(prevMetrics.instagram, igType);
  const prevTwitterData = getTwitterData(prevMetrics.twitter);
  const prevTiktokData = getTiktokData(prevMetrics.tiktok);
  const prevYouTubeData = getYouTubeData(prevMetrics.youtube);
  // Disable for now
  // const prevAppleMusicData = getAppleMusicData(prevMetrics.appleMusic);

  const prevSpotifyData = getSpotifyData(prevMetrics.spotify);
  const hiddenSubscriber =
    youtubeMetrics.length > 0 &&
    youtubeMetrics[youtubeMetrics.length - 1].hidden_subscriber;

  const metricsCount = {
    fbFollowerCount,
    igFollowerCount,
    twitterFollowerCount,
    tiktokFollowerCount,
    youtubeFollowerCount,
    appleMusicListenersCount,
    spotifyFollowerCount,
  };

  let columns: any = 4;

  let existingFollowers = 0;
  Object.keys(metricsCount).forEach((column) => {
    if (metricsCount[column] || metricsCount[column] === 0) {
      if (metricsCount[column] !== undefined) {
        existingFollowers++;
      }
    }
  });
  if (existingFollowers > 0) {
    let count = existingFollowers;
    if (count === 6) {
      // setColumns(2);
      columns = 2;
    } else if (count === 5) {
      // setColumns(2);
      columns = 2;
    } else if (count === 4) {
      // setColumns(3);
      columns = 3;
    } else if (count === 3) {
      // setColumns(4);
      columns = 4;
    } else if (count === 2) {
      // setColumns(6);
      columns = 6;
    } else if (count === 1) {
      // setColumns(12);
      columns = 12;
    }
  }

  return (
    <div>
      <Grid
        className={classes.profileContainer}
        container
        id="profileContainer"
      >
        <Grid className={classes.stats} id="stats" item xs container>
          {!igMetricsLoading ? (
            igMetrics.length > 0 &&
            igFollowerCount && (
              <Grid
                item
                xs={12}
                md={columns}
                style={{
                  alignContent: "center",
                }}
              >
                <MetricsIndicatorV2
                  icon={InstagramIcon}
                  metrics={metricsCount}
                  role="instagram"
                  key="instagram-indicator"
                />
              </Grid>
            )
          ) : (
            <CustomLoader marginTop="0" />
          )}

          {!fbMetricsLoading ? (
            fbMetrics.length > 0 &&
           (
              <Grid item xs={12} md={columns}>
                <MetricsIndicatorV2
                  icon={FacebookIcon}
                  metrics={metricsCount}
                  role="facebook"
                  key="facebook-indicator"
                />
              </Grid>
            )
          ) : (
            <CustomLoader marginTop="0" />
          )}

          {!twitterMetricsLoading ? (
            twitterMetrics.length > 0 &&
            twitterFollowerCount > 0 && (
              <Grid item xs={12} md={columns}>
                <MetricsIndicatorV2
                  icon={TwitterIcon}
                  metrics={metricsCount}
                  role="twitter"
                  key="twitter-indicator"
                />
              </Grid>
            )
          ) : (
            <CustomLoader marginTop="0" />
          )}

          {!tiktokMetricsLoading ? (
            tiktokMetrics.length > 0 &&
            tiktokFollowerCount > 0 && (
              <Grid
                item
                xs={12}
                md={columns}
                style={{
                  display: "flex",
                  alignContent: "center",
                }}
              >
                <MetricsIndicatorV2
                  icon={TiktokIcon}
                  metrics={metricsCount}
                  role="tiktok"
                  key="tiktok-indicator"
                />
              </Grid>
            )
          ) : (
            <CustomLoader marginTop="0" />
          )}

          {!youtubeLoading ? (
            youtubeMetrics.length > 0 &&
            youtubeFollowerCount && (
              <Grid item xs={12} md={columns}>
                <MetricsIndicatorV2
                  icon={YouTubeIcon}
                  metrics={metricsCount}
                  role="YouTube"
                  key="youtube-indicator"
                  hiddenSubscriber={hiddenSubscriber}
                />
              </Grid>
            )
          ) : (
            <CustomLoader marginTop="0" />
          )}
          {!spotifyMetricsLoading ? (
            spotifyMetrics.length > 0 &&
            spotifyFollowerCount && (
              <Grid item xs={12} md={columns}>
                <MetricsIndicatorV2
                  icon={SpotifyIcon}
                  metrics={metricsCount}
                  url={user.spotify?.url}
                  role="Spotify"
                  key="spotify-indicator"
                />
              </Grid>
            )
          ) : (
            <CustomLoader marginTop="0" />
          )}

          {twitterMetrics.length < 1 &&
            igMetrics.length < 1 &&
            fbMetrics.length < 1 &&
            tiktokMetrics.length < 1 &&
            spotifyMetrics.length < 1 &&
            youtubeMetrics.length < 1 &&
            appleMusicMetrics.length < 1 &&
            (twitterMetricsLoading ||
            igMetricsLoading ||
            fbMetricsLoading ||
            tiktokMetricsLoading ||
            spotifyMetricsLoading ||
            youtubeLoading ? (
              <CustomLoader />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Avenir Book, sans-serif",
                  marginLeft: "40px",
                }}
              >
                No metrics for this period
              </div>
            ))}
        </Grid>
      </Grid>
      <div className={classes.statsWrapper}>
        {igFollowerCount && igFollowerCount < 100 ? (
          <div id="ig-stats">
            <div className={classes.socialLabel}>
              <Image
                className={classes.icon}
                src={InstagramIcon}
                alt={"instagram"}
              />
              <span className={classes.statsName}>
                {capitalize("instagram")}
              </span>
            </div>
            <ErrorMessage message="No metrics due to < 100 Instagram followers" />
          </div>
        ) : (
          <>
            {!igMetricsLoading ? (
              (igData?.dates?.length > 0 || igType === "stories") &&
              igFollowerCount ? (
                <div id="ig-stats">
                  {/* <Metric
                    icon={InstagramIcon}
                    metrics={igData}
                    role="instagram"
                    key="instagram-metric"
                    igType={igType}
                    igHandler={igHandler}
                    mediaLoading={igLoading}
                    posts={igPosts}
                    igMetadata={igMetadata}
                    prevLoading={prevLoading}
                    prevMetrics={{ ...prevIgData }}
                  /> */}
                  <MetricV2IGIPosts
                    icon={InstagramIcon}
                    metrics={{ ...igData }}
                    role="instagram"
                    key="instagram-metric"
                    igType={igType}
                    igHandler={igHandler}
                    mediaLoading={igLoading}
                    posts={igPosts}
                    igMetadata={igMetadata}
                    url={user.instagram.url}
                    prevLoading={prevLoading}
                    prevMetrics={{ ...prevIgData }}
                    dateRange={dateRange}
                  />

                  <MetricV2Instagram
                    icon={InstagramIcon}
                    metrics={{ ...igData }}
                    role="instagram"
                    key="instagram-metric"
                    igType={igType}
                    igHandler={igHandler}
                    mediaLoading={igLoading}
                    igMetadata={igMetadata}
                    url={user.instagram.url}
                    prevLoading={prevLoading}
                    prevMetrics={{ ...prevIgData }}
                    dateRange={dateRange}
                  />
                </div>
              ) : null
            ) : (
              <Loader />
            )}
          </>
        )}

        {!fbMetricsLoading ? (
          fbData?.dates?.length > 0 && (
            <div id="fb-stats">
              <MetricV2
                icon={FacebookIcon}
                metrics={{ ...fbData }}
                role="facebook"
                key="facebook-metric"
                mediaLoading={fbLoading}
                posts={fbPosts}
                fbMetadata={fbMetadata}
                url={user.facebook.url}
                prevLoading={prevLoading}
                prevMetrics={{ ...prevFbData }}
                dateRange={dateRange}
              />
            </div>
          )
        ) : (
          <Loader />
        )}

        {!twitterMetricsLoading ? (
          twitterMetrics.length > 0 &&
          twitterFollowerCount &&
          dateRange && (
            <div id="twitter-stats">
              <MetricV2
                icon={TwitterIcon}
                metrics={{ ...twitterData }}
                role="twitter"
                key="twitter-metric"
                mediaLoading={twitterLoading}
                posts={twitterPosts}
                url={user.twitter.url}
                prevLoading={prevLoading}
                prevMetrics={{ ...prevTwitterData }}
                dateRange={dateRange}
              />
            </div>
          )
        ) : (
          <Loader />
        )}

        {!tiktokMetricsLoading ? (
          tiktokMetrics.length > 0 &&
          tiktokFollowerCount > 0 && (
            <div id="tiktok-stats">
              <TiktokMetrics
                icon={TiktokIcon}
                metrics={{ ...tiktokData }}
                role="tikTok"
                key="tiktok-metric"
                mediaLoading={tiktokLoading}
                posts={tiktokPosts}
                url={user.tiktok?.url}
                prevLoading={prevLoading}
                prevMetrics={{ ...prevTiktokData }}
                dateRange={dateRange}
              />
            </div>
          )
        ) : (
          <Loader />
        )}

        {!youtubeLoading ? (
          youtubeMetrics.length > 0 &&
          youtubeFollowerCount && (
            <div id="youtube-stats">
              <MetricV2
                icon={YouTubeIcon}
                metrics={{ ...youtubeData }}
                role="YouTube"
                key="youtube-metric"
                mediaLoading={youtubeLoading}
                posts={youtubePosts}
                url={user.youtube?.url}
                prevLoading={prevLoading}
                prevMetrics={{ ...prevYouTubeData }}
                hiddenSubscriber={hiddenSubscriber}
                dateRange={dateRange}
              />
            </div>
          )
        ) : (
          <Loader />
        )}

        {/* Hide Apple Music Stats */}
        {/* {appleMusicMetrics.length > 0 && (
          <div id="apple-music-stats">
            <Metric
              icon={AppleMusicIcon}
              metrics={{ ...appleMusicData }}
              role="Apple Music"
              key="apple-music-metric"
              mediaLoading={appleMusicLoading}
              posts={appleMusicPosts}
              prevLoading={prevLoading}
              prevMetrics={{ ...prevAppleMusicData }}
            />
          </div>
        )} */}
        {!spotifyMetricsLoading ? (
          spotifyMetrics.length > 0 && (
            <div id="spotify-stats">
              <MetricV2
                icon={SpotifyIcon}
                metrics={{ ...spotifyData }}
                role="Spotify"
                key="spotify-metric"
                mediaLoading={spotifyLoading}
                posts={spotifyPosts}
                url={user.spotify?.url}
                prevLoading={prevLoading}
                prevMetrics={{ ...prevSpotifyData }}
                dateRange={dateRange}
              />
            </div>
          )
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};
