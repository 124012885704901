import React from "react";
import { useStylesV2 } from "../stylesV2";

import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const InstagramMediaTitle = ({ role, metaData }: { role: string; metaData: any }) => {
  const classes = useStylesV2({});

  const message =
    "These are the posts that have the highest reach which were posted in the chosen period." +
    `${
      role === "instagram"
        ? " IGTV and Reel posts are not displayed because the Instagram API does not provide this data"
        : ""
    }`;
  return (
    <>
      {" "}
      <div
        className={classes.statsName}
        style={{
          justifyContent: "space-between",
          fontSize: "20px",
          padding: "10px 0px 25px 0px",
          fontFamily: "Avenir Book, sans-serif",
          fontWeight: 900,
        }}
      >
        <span className={classes.biostyle}>
          Account Type:{" "}
          <span
            style={{
              fontWeight: 300,
            }}
          >
            Business Account
          </span>{" "}
        </span>
        <br />
        <span className={classes.biostyle}>
          Bios:{" "}
          <span
            style={{
              fontWeight: 300,
            }}
          >
            {metaData?.biography}
          </span>{" "}
        </span>
        <br />
        <span className={classes.biostyle}>
          Username:{" "}
          <span
            style={{
              fontWeight: 300,
            }}
          >
            {metaData?.username}
          </span>
        </span>
        <br />

        <span>Top 3 Highest Engagement Posts</span>
        <Tooltip title={message} arrow interactive classes={{ tooltip: classes.info }}>
          <InfoIcon />
        </Tooltip>
      </div>
    </>
  );
};

export default InstagramMediaTitle;
