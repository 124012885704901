import React, { FunctionComponent, useState } from "react";
// import { Grid, Collapse, Button } from "@material-ui/core";
// import { Select, MenuItem, Grid, Collapse, Button } from "@material-ui/core";

import { useStyles } from "../styles";
import { IndicatorProps } from "../MetricsIndicator";
// import AreaChart from "./Chart";
// import { radialBar, bar, area } from "./ChartV2/chartOptions";
import Media from "../Media";
import CustomLoader from "../../CustomLoader";
// import { numberWithCommas } from "utils/numberFormat";
import { capitalize } from "utils/stringFormat";
import ToggleIgPost from "../ToggleIgPost";
import InstagramMediaTitle from "../Media/InstagramMediaTitle";
import Fade from "../Fade";

interface MetricProps extends IndicatorProps {
  igType?: string;
  igHandler?: (e: any) => void;
  mediaLoading?: boolean;
  posts: Array<any>;
  igMetadata?: any;
  fbMetadata?: any;
  prevLoading: boolean;
  prevMetrics: any;
  hiddenSubscriber?: boolean;
  dateRange?: {
    startDate: any;
    endDate: any;
  };
}

const defaultTextStyle = {
  color: "#606060",
  fontFamily: "Avenir",
  fontSize: "21px",
  fontStyle: "normal",
  fontWeight: 900,
  lineHeight: "24px",
  letterSpacing: "0.5px",
  marginRight: 10,
};

const selectedTextStyle = {
  textDecoration: "underline",
  color: "black",
};

const fetchingMessage = (platform: string) => {
  switch (platform) {
    case "YouTube":
      return "Fetching top 10 videos...";
    case "Apple Music":
      return "Fetching top 10 songs...";
    default:
      return "Fetching top 3 posts...";
  }
};

const MetricV2IGIPosts: FunctionComponent<MetricProps> = ({
  role,
  metrics: metricsData,
  icon,
  igType,
  igHandler,
  mediaLoading,
  posts,
  igMetadata,
  fbMetadata,
  url,
  prevLoading,
  prevMetrics,
  hiddenSubscriber,
  dateRange,
}) => {
  // const [totalFollowers, setTotalFollowers] = useState(0);
  const [mediaType, setMediaType] = useState("IMAGE");

  // const [dateList, setDateList] = useState(null);

  let metrics = metricsData;
  const midpoint = Math.ceil(metricsData.dates.length / 2);
  let metricsDates = metricsData.dates.slice(midpoint);
  metrics.dates = metricsDates;

  metrics.dates = metricsDates;

  const classes = useStyles({});

  let videoPost: any[] = [];
  let imagePost: any[] = [];

  if (posts?.length) {
    for (let item of posts) {
      if (["IMAGE", "CAROUSEL_ALBUM"].includes(item?.metadata?.media_type)) {
        imagePost.push(item);
      } else if (["VIDEO"].includes(item?.metadata?.media_type)) {
        videoPost.push(item);
      }
    }
  }

  const mediaCheck = (posts) =>
    posts?.length === 0 ? null : (
      <Media
        posts={posts}
        role={role}
        fbMetadata={fbMetadata}
        igMetadata={igMetadata}
      />
    );

  const updateMediaVideo = () => {
    setMediaType("VIDEO");
  };
  const updateMediaImage = () => {
    setMediaType("IMAGE");
  };

  return (
    <div style={{ marginBottom: "15px" }}>
      {(posts?.length !== 0 || metrics.dates.length > 0 || mediaLoading) && (
        <div className={classes.socialLabel}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <img className={classes.icon} src={icon} alt={role} />
          </a>
          <span className={classes.statsName}>
            {" "}
            {role === "twitter" ? "X" : capitalize(role)}
          </span>
        </div>
      )}

      {!mediaLoading && ["instagram"].includes(role) && (
        <div
          style={{
            marginRight: 10,
            marginLeft: 15,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <label
            onClick={updateMediaImage}
            style={
              mediaType === "IMAGE"
                ? {
                    ...defaultTextStyle,
                    ...selectedTextStyle,
                  }
                : defaultTextStyle
            }
          >
            {" "}
            Post{" "}
          </label>
          <label
            onClick={updateMediaVideo}
            // onClick={() => setMediaType("VIDEO")}
            style={
              mediaType === "VIDEO"
                ? { ...defaultTextStyle, ...selectedTextStyle }
                : defaultTextStyle
            }
          >
            {" "}
            Reel{" "}
          </label>{" "}
        </div>
      )}

      {!mediaLoading && ["instagram"].includes(role) && (
        <>
          <InstagramMediaTitle role={role} metaData={igMetadata} />

          {mediaType === "VIDEO" && videoPost.length > 0 && (
            <ToggleIgPost
              media_type={mediaType}
              posts={videoPost}
              role={role}
              fbMetadata={fbMetadata}
              igMetadata={videoPost}
              avatar={igMetadata}
            />
          )}
          {mediaType === "IMAGE" && imagePost.length > 0 && (
            <ToggleIgPost
              media_type={mediaType}
              posts={imagePost}
              role={role}
              fbMetadata={fbMetadata}
              igMetadata={imagePost}
              avatar={igMetadata}
            />
          )}
        </>
      )}

      {!mediaLoading &&
      !["YouTube", "Apple Music", "Spotify", "instagram"].includes(role)
        ? mediaCheck(posts)
        : ""}

      <br />
      <Fade show={!!mediaLoading}>
        <div className={classes.mediaLoading2}>
          <div className={classes.mediaLoader}>{fetchingMessage(role)}</div>
        </div>
      </Fade>
      <Fade show={!!mediaLoading}>
        <div className={classes.mediaLoading3}>
          <CustomLoader width="15%" />
        </div>
      </Fade>
    </div>
  );
};

const areEqual = (prevProps, nextProps): any => {
  if (prevProps.metrics === nextProps.metrics) {
    return true;
  }
};

export default React.memo(MetricV2IGIPosts, areEqual);
